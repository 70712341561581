.home-page {
	display: flex;
	/* flex-wrap: wrap; */
	align-items: flex-start;
}
.portfolio-page {
	.right-bar-content {
		a {
			text-decoration: underline !important;
		}
	}
}
//disable fslightboxs images right clicked to protect image from download
img.fslightboxs{
	pointer-events: none;
}

@media screen and (max-width: 540px) {
	.home-page {
		display: flex;
		/* flex-wrap: wrap; */
		flex-direction: column;
		.imageWrapper {
			flex-direction: column;
			.figure {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: 1023px) {
	.home-page,
	.portfolio-page {
		display: flex;
		/* flex-wrap: wrap; */
		flex-direction: column;
		.left-bar-content {
			padding: 0;
			.site-header {
				display: flex;
				padding: 0;
				height: 10vh;
				width: 100vw;
				background: white;
				position: sticky;
				.logo-wrap {
					margin: auto;
					.has-rollover {
						width: auto;
					}
				}
				.hamburger-click-area {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 10%;
				}
			}
		}
		.right-bar-content {
			width: 100%;
			padding: 5%;
		}
	}
}
