figure {
	img {
		display: flex;
		width: 80%;
		margin: auto;
		padding-bottom: 1.5rem;
		&:last-of-type {
			padding-bottom: 0;
		}
	}
}
.imgWrapper1 {
	display: flex;
	margin: auto;
	padding-bottom: 1.5rem;
}
.imgWrapper3 {
	display: flex;
	flex-wrap: wrap;
	padding: 1.5rem 0;
	img {
		width: 50%;
		&:last-of-type {
			padding-bottom: 1.5rem;
		}
	}
}
.imgWrapper4 {
	display: flex;
	flex-wrap: wrap;
	img {
		width: 25%;
		&:last-of-type {
			padding-bottom: 1.5rem;
		}
	}
}
.masterQuoteFigure {
	img {
		display: flex;
		width: 80%;
		margin: auto;
		padding-bottom: 1.5rem;
		&:last-of-type {
			padding-bottom: 0;
		}
	}
}
