.portfolio-page,
.home-page {
	display: flex;
	align-items: flex-start;
	flex-wrap: nowrap;
}
.left-bar-content {
	z-index: 100;
	flex: 0 0 25%;
	padding-left: 2%;
	padding-right: 4%;
	position: sticky;
	top: 0;
	header {
		padding: 10% 0;
	}
	nav {
		ul {
			padding: 0;
			margin: 0;
			text-align: left;
		}
		li {
			padding: 0;
			margin: 0;
			list-style-type: none;
			padding-bottom: 6px;
		}
		.about {
			float: left;
			padding-top: 20%;
		}
	}
}
.right-bar-content {
	width: 75%;
}

@media screen and (min-width: 1024px) {
	.right-bar-content {
		padding-right: 2%;
	}
}

.portfolio-page {
	padding-bottom: 3rem;
	.right-bar-content {
		text-align: left;
	}
	p {
		padding-top: 40px;
		margin: 0;
		font-size: 16px;
		line-height: 1.75rem;
	}
	figure {
		// position: relative;
		// z-index: 0;
		margin: 0;
		padding: 20px 0;
		img {
			max-width: 100%;
		}
		figcaption {
			color: #999999;
			font-size: smaller;
			text-align: center;
			padding-top: 15px;
		}
		a {
			color: #999999;
		}
	}
	.flexImageContainer {
		display: flex;
		flex-wrap: wrap;
		* {
			width: 50%;
		}
		figcaption {
			flex: 0 0 100%;
			text-align: right;
		}
		img {
			&:last-child {
				width: 100%;
			}
		}
	}
}

// logo hover
.has-rollover {
	position: relative;
	width: 46.5%;
	max-width: 100px;
	img {
		max-height: 100%;
		max-width: 100%;
	}
	.image-rollover {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		object-fit: contain;
		opacity: 0;
		transition: opacity 0.1s;
	}
	&:hover {
		.image-rollover {
			opacity: 1;
		}
	}
}
.hamburger-click-area {
	display: none;
}

//Mobile Nav
@media screen and (max-width: 1023px) {
	.nav {
		display: none;
		&.open .group {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		&.open .about {
			width: 100%;
		}
	}
	.hamburger-click-area {
		display: block;
	}
	.nav.open {
		display: block;
		min-height: 100vh;
		background: white;
		padding: 0 10%;
		padding-top: 20%;
	}
}

//Tablet Nav
@media screen and (max-width: 768px) {
	.nav {
		&.open .group {
			align-items: flex-start;
		}
	}
}
