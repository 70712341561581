.imageWrapper {
	display: flex;
	flex-wrap: wrap;
}
.imageWrapper img {
	flex-basis: 50%;
	width: 50%;
}
.figure {
	width: 50%;
}
img.image-hover {
	display: none;

}
img.image-main {
    display: block;
	width: 100%;
	height: 100%;
}

.figure:hover {
	img.image-hover {
		display: block;
		width: 100%;
		height: 100%;
	}
    img.image-main{
        display: none;
    }
}
