body {
  margin: 0;
  font-family: 'Noto Sans SC','Lexend', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: none;
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
text-decoration: none;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}

a:hover {
  text-decoration: underline;
  color: #000;
}

a:active {
  color: #000;
}

.active{
  text-decoration: underline;
  }