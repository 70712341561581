.Illustration{
    .imageWrapper{
        display: flex;
        flex-wrap: wrap;
    }
    .imageWrapper img{
        flex-basis: 50%;
        width: 50%;
        padding: 0;
    }
    figure img {
        width: 100%;
        padding:0;
    }
    .flexAdjust{
        &:first-of-type {
            flex-basis: 70%;
            width: 70%;
        }
        &:last-of-type{
            flex-basis: 30%;
            width: 30%;
        }
    }
}